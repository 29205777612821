import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './plugins';
import vuetify from './plugins/vuetify';
// Plugins
import './plugins/axios';

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  vuetify,
}).$mount('#app');
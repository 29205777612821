export function generateBannerImage(serviceDBName) {
  switch (serviceDBName) {
    case 'angielski ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/angielski.png',
        serviceIcon: '/img/evaluation/services_icon/angielski.png',
      };
    case 'LET\'S TALK angielski ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/lets-talk.png',
        serviceIcon: '/img/evaluation/services_icon/lets-talk.png',
      };
    case 'matematyka ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/matematyka.png',
        serviceIcon: '/img/evaluation/services_icon/matematyka.png',
      };
    case 'matematyka z Minecraftem ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/matematyka-z-minecraftem.png',
        serviceIcon: '/img/evaluation/services_icon/matematyka-z-minecraftem.png',
      };
    case 'rysunek ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/rysunek.png',
        serviceIcon: '/img/evaluation/services_icon/rysunek.png',
      };
    case 'gitara ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/gitara.png',
        serviceIcon: '/img/evaluation/services_icon/gitara.png',
      };
    case 'programowanie ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/programowanie.png',
        serviceIcon: '/img/evaluation/services_icon/programowanie.png',
      };
    case 'MAT Egzamin 8 ONLINE':
    case 'ANG Egzamin 8 ONLINE':
    case 'POL Egzamin 8 ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/egzamin.png',
        serviceIcon: '/img/evaluation/services_icon/egzamin.png',
      };
    case 'szachy ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/szachy.png',
        serviceIcon: '/img/evaluation/services_icon/szachy.png',
      };
    case 'grafika ONLINE':
    case 'Grafika MarkWeb ONLINE':
    case 'Grafika RysFot ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/grafika.png',
        serviceIcon: '/img/evaluation/services_icon/grafika.png',
      };
    case 'Lego':
      return {
        bannerImage: '/img/evaluation/services_banner/lego.png',
        serviceIcon: '/img/evaluation/services_icon/lego.png',
      };
    case 'RUSHsię! ONLINE':
      return {
        bannerImage: '/img/evaluation/services_banner/rush-sie.png',
        serviceIcon: '/img/evaluation/services_icon/rush-sie.png',
      };
    default:
      return {};
  }
}

export function generateServiceName(serviceDBName) {
  switch (serviceDBName) {
    case 'LET\'S TALK angielski ONLINE':
      return 'LET\'S TALK ANGIELSKI';
    case 'MAT Egzamin 8 ONLINE':
      return 'EGZAMIN ÓSMOKLASISTY MATEMATYKA';
    case 'ANG Egzamin 8 ONLINE':
      return 'EGZAMIN ÓSMOKLASISTY JĘZYK ANGIELSKI';
    case 'POL Egzamin 8 ONLINE':
      return 'EGZAMIN ÓSMOKLASISTY JĘZYK POLSKI';
    case 'grafika ONLINE':
      return 'Akademia Młodego Grafika';
    case 'Grafika MarkWeb ONLINE':
      return 'Marketingowiec i WebDesigner';
    case 'Grafika RysFot ONLINE':
      return 'Wirtualny Rysownik&FotoGrafik';
    default:
      return serviceDBName.replace('ONLINE', '').toUpperCase().trim();
  }
}

export function generateSectionIcon(title) {
  switch (title.trim()) {
    case 'Przygotowanie do zajęć':
      return '/img/evaluation/prepare-to-lecture.png';
    case 'Umiejętności interpersonalne i zaangażowanie':
      return '/img/evaluation/personal-skills.png';
    case 'Aspekty techniczne':
      return '/img/evaluation/technical-aspects.png';
    case 'Zakres osiągnięć':
    case 'Zakres osiągnieć':
      return '/img/evaluation/achivements.png';
    case 'Podsumowanie':
      return '/img/evaluation/summary.png';
    default:
      return '/img/evaluation/prepare-to-lecture.png';
  }
}

export function generateQuestionsAndAnswers(questions, answers) {
  const questionsAndAnswers = [];
  questions.forEach((element, index) => {
    questionsAndAnswers.push({
      id: element.id,
      question: element.text,
      answer: answers[index].text,
    });
  });
  return questionsAndAnswers;
}

export function generateAnswer(questionId, diplomaAnswers) {
  const diplomaAnswer = diplomaAnswers.filter((x) => questionId === x.id_question);
  if (diplomaAnswer.length > 0) {
    return diplomaAnswer[0];
  }
  return {};
}

export function generateArray(diplomaQuestions, diplomaAnswers) {
  const sorted = diplomaQuestions.sort((a, b) => ((a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)));
  const noRepetitions = [];
  sorted.forEach((element) => {
    const repetitions = noRepetitions.filter((x) => x.id === element.id).length;
    if (!repetitions) {
      noRepetitions.push({ ...element, answer: generateAnswer(element.id, diplomaAnswers) });
    }
  });

  return noRepetitions;
}

export function generateAnswers(text, length, order) {
  const answers = [];
  answers.push(text);
  for (let i = 1; i <= length; i++) {
    const isChecked = i === order;
    answers.push(isChecked);
  }
  return answers;
}

export function generateGrid(evaluationAnswers, diplomaQuestions, diplomaAnswers) {
  const grid = [];
  const answers = [];
  answers.push('logo');
  const sortedDiplomaQuestions = generateArray(diplomaQuestions, diplomaAnswers);
  for (let i = 0; i < evaluationAnswers.length; i++) {
    answers.push(evaluationAnswers[i].text);
  }
  grid.push(answers);
  sortedDiplomaQuestions.forEach((element) => {
    if (element.answer && element.answer.order !== null) {
      grid.push(generateAnswers(element.text, evaluationAnswers.length, element.answer.order));
    }
  });
  return grid;
}

export const allowBoldTag = (str) => str.toString().replace(/&lt;b&gt;/g, '<b>').replace(/&lt;\/b&gt;/g, '</b>');

export const toBold = (text) => text.replace(/\*\*(.*?)\*\*/gm, '<b>$1</b>');
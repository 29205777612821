<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Link do dyplomu jest nieprawidłowy</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
};
</script>
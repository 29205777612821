<template>
  <div>
    <div v-if="img" class="top-bar">
      <p style="color: #fff; font-size: 25px;">Pobierz Pełen Certyfikat {{ $route.params.name }}</p>
      <button
        style="background-color: #191B1C; color: #fff; padding: 10px;"
        class="download-button"
        @click="print"
      >
        POBIERZ
      </button>
    </div>
    <div
      class="home"
      :style="{ backgroundImage: `url(${img})` }"
    >
      <p class="text">{{ img ? $route.params.name : 'Nie ma takiego kursu' }}</p>
      <p class="date" v-if="img">{{ day }}</p>
    </div>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import font from '../data/lora';
import grafikaNaCoDzienONLINE from '../../public/images/grafikaNaCoDzien_1.png';
import grafikaNaCoDzienBackPage from '../../public/images/grafikaNaCoDzienBackPage.png';

export default {
  name: 'GrafikaDiploma',
  components: {
  },
  data() {
    return {
      images: {
        grafikaNaCoDzienONLINE,
        grafikaNaCoDzienBackPage,
      },
      doc: new jsPDF('p', 'mm', 'a4'),
      img: {},
      course: '',
      day: '',
    };
  },
  created() {
    this.course = this.$route.params.course;
    this.course = this.course.replaceAll(' ', '');
    this.course = this.course.replaceAll('ę', 'e');
    this.course = this.course.replaceAll('!', '');
    this.course = this.course.replaceAll('\'', '');
    this.generateBackgroundImage();
  },
  mounted() {
    this.addFont();
    this.generateDate();
  },
  methods: {
    generateDate() {
      const date = new Date().toLocaleDateString();
      this.day = date;
    },
    generateBackgroundImage() {
      this.img = this.images[this.course];
    },
    addFont() {
      this.doc.addFileToVFS('Lora-Regular.tff', font);
      this.doc.addFont('Lora-Regular.tff', 'Lora-Regular', 'normal');
    },
    async print() {
      const width = this.doc.internal.pageSize.getWidth();
      const height = this.doc.internal.pageSize.getHeight();

      const imageJs = await import('../data/images/grafikaNaCoDzienONLINE');
      const imgData = `data:image/jpeg;base64,${imageJs.default}`;

      const imageJsTwo = await import('../data/images/grafikaNaCoDzienBackPage');
      const imgDataTwo = `data:image/jpeg;base64,${imageJsTwo.default}`;

      this.doc.addImage(imgData, 'PNG', 0, 0, width, height);
      this.doc.setFont('Lora-Regular', 'normal');
      this.doc.setFontSize(46);
      this.doc.setTextColor(44, 38, 51);
      this.doc.text(this.$route.params.name, width / 2, 108, { align: 'center' });
      this.doc.setFontSize(15);
      this.doc.text(141, 220, this.day);
      this.doc.addPage();
      this.doc.addImage(imgDataTwo, 'PNG', 0, 0, width, height);

      this.doc.save(`Dyplom ${this.$route.params.name} - ${this.course}.pdf`);
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "Lora Regular";
  src: local("Lora-Regular"),
  url('../fonts/Lora-Regular.ttf') format("truetype");
}
.top-bar {
  background-color: #323639;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home {
  position: relative;
  margin: auto;
  width: 826px;
  height: 1169px;
}
.text {
  position: absolute;
  padding-top: 380px;
  width: 100%;
  text-align: center;
  /* left: 252px;
  top: 380px; */
  font-size: 62px;
  color: rgb(20, 18, 18);
  font-family: "Lora Regular";
}
.date {
  position: absolute;
  left: 590px;
  top: 876px;
  font-size: 15px;
  font-weight: bold;
  color: rgb(20, 18, 18);
}
.top-bar > * {
  margin: 0px 15px;
}
</style>
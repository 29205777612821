import Vue from 'vue';

import axios from 'axios';

let baseURL = 'http://localhost:4401/';
if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://tabulatura-ns.szkolamm.pl/';
}

const axiosInstance = axios.create({
  baseURL,
  timeout: 30000,
});

Vue.prototype.$http = axiosInstance;

export default axiosInstance;
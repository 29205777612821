<template>
  <div>
    <lead-baner>
      <v-container class="p-r content-vertical">

        <div style="display: flex; justify-content: center; flex-direction: column; padding-top: 56px;" class="text-center text-block-size">
          <span style="font-weight: 600; font-size: 24px; color: #1d1d4e;">{{ studentFullName }}</span>
          <span style="font-weight: 600; font-size: 24px; color: #1d1d4e; margin-top: 30px;">{{ serviceDBName }}</span>
          <span style="font-size: 14px; color: #1d1d4e;">Dziękujemy, że jesteście z nami! Oto podsumowanie pracy {{ studentName }} na przestrzeni ostatnich miesięcy z Tutore.</span>
        </div>
      </v-container>
    </lead-baner>
    <v-row class="evaluation-wrapper d-flex align-center">
      <v-col
        cols="12"
      >
        <screen-loader :active="loading" :full-screen="true" :sticky="true">

        <div v-if="loading" class="cover-view" />
        <div
          class="diploma-record"
        >
          <div
            v-for="(diploma, diplomaIndex) in diplomaData"
            :key="diplomaIndex"
            class="mt-12"
            style="display: flex; flex-direction: column; align-items: center;"
          >
            <div
              v-if="diploma && diploma.grid && diploma.grid.length > 1"
              style="width: 100%;"
            >
              <div
                :style="`display: flex; flex-direction: row; margin: auto; margin-bottom: 16px;
                margin-left: ${diploma.title.trim() !== 'Podsumowanie' ? '0px' : '0px'};`"
              >
                <div v-if="!loading" class="image-container-32">
                  <v-img
                    :src="generateSectionIcon(diploma.title)"
                    width="32px"
                    height="32px"
                  />
                </div>

                <span
                  style="font-size: 22px; font-weight: 600; margin-left: 8px; text-align: left; color: #1d1d4e;"
                >{{ diploma.title }}:</span>
              </div>
              <div
                v-if="diploma.title.trim() !== 'Podsumowanie'"
                style="background-color: #fff; border-radius: 16px; padding: 24px 22px;"
              >
                <div
                  v-for="(g, gridIndex) in diploma.grid"
                  :key="gridIndex"
                >
                  <div
                    :class="`grid-${diploma.evaluationAnswers.length}`"
                  >
                    <div
                      v-for="(gridElement, gridElementIndex) in g"
                      :key="gridElementIndex"
                      :class="[ gridElementIndex === 0 ? 'primary-block justify-start ' : 'secondary-block',
                                gridIndex === 0 ? 'primary-block' : 'secondary-block']"
                    >
                      <span
                        v-if="(gridElementIndex === 0 || gridIndex === 0) && gridElement !== 'logo'"
                        style="font-weight: 600; font-size: 12px; text-align: center;"
                      >{{ gridElement }}</span>
                      <div
                        v-if="gridElement === true"
                        style="width: 16px; height: 16px; border-radius: 50%; background-color: rgba(34, 192, 109, 0.54);"
                      />
                      <div
                        v-if="gridElement === false"
                        style="width: 16px; height: 16px; border-radius: 50%; background-color: rgba(34, 192, 109, 0.06);"
                      />
                      <div
                        v-if="gridElement === 'logo'"
                        class="question-logo"
                      />
                    </div>
                  </div>
                  <v-divider v-if="gridIndex + 1 < diploma.grid.length" />
                </div>
              </div>
              <div
                v-else
                style="background-color: #fff; color: #1d1d4e;
                padding: 24px 22px; border-radius: 8px;"
                >
                <p
                  v-if="diploma.dip_answers && diploma.dip_answers[0] && diploma.dip_answers[0].text"
                  v-html="allowBoldTag(toBold( diploma.dip_answers[0].text.replaceAll('[Imię dziecka]', `**${studentName.trim()}**`)))"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="loading"
          id="capture"
          class="diploma-record-pdf ml-2"
        >
          <div style="display: flex; justify-content: center; flex-direction: column; padding-top: 56px;" class="text-center text-block-size">
            <span style="font-weight: 600; font-size: 24px; color: #1d1d4e;">{{ studentFullName }}</span>
            <span style="font-weight: 600; font-size: 24px; color: #1d1d4e;">{{ serviceDBName }}</span>
            <span style="font-size: 14px; color: #1d1d4e;">Dziękujemy, że jesteś z nami! To dla nas wyróżnienie! Sprawdź podsumowanie postępów</span>
          </div>
          <div
            v-for="(diploma, diplomaIndex) in diplomaData"
            :key="diplomaIndex"
            style="display: flex; flex-direction: column; align-items: center; margin-top: 48px;"
          >
            <div
              v-if="diploma && diploma.grid && diploma.grid.length > 1"
              style="width: 100%;"
            >
              <div
                :style="`display: flex; flex-direction: row; margin: auto; margin-bottom: 16px;
                margin-left: ${diploma.title.trim() !== 'Podsumowanie' ? '0px' : '0px'};`"
              >
                <span
                  style="font-size: 22px; font-weight: 600; margin-left: 8px; text-align: left; color: #1d1d4e;"
                >{{ diploma.title }}:</span>
              </div>
              <div
                v-if="diploma.title.trim() !== 'Podsumowanie'"
                style="background-color: #fff; border-radius: 16px; padding: 24px 22px;"
              >
                <div
                  v-for="(g, gridIndex) in diploma.grid"
                  :key="gridIndex"
                >
                  <div
                    :class="`grid-${diploma.evaluationAnswers.length}`"
                  >
                    <div
                      v-for="(gridElement, gridElementIndex) in g"
                      :key="gridElementIndex"
                      :class="[ gridElementIndex === 0 ? 'primary-block ' : 'secondary-block ',
                                gridIndex === 0 ? 'primary-block' : 'secondary-block']"
                    >
                      <span
                        v-if="(gridElementIndex === 0 || gridIndex === 0) && gridElement !== 'logo'"
                        class="text-center"
                        style="font-weight: 600; font-size: 12px;"
                      >{{ gridElement }}</span>
                      <div
                        v-if="gridElement === true"
                        style="width: 16px; height: 16px; border-radius: 50%; background-color: rgba(34, 192, 109, 0.54);"
                      />
                      <div
                        v-if="gridElement === false"
                        style="width: 16px; height: 16px; border-radius: 50%; background-color: rgba(34, 192, 109, 0.06);"
                      />
                      <div
                        v-if="gridElement === 'logo'"
                        class="question-logo"
                      />
                    </div>
                  </div>
                  <v-divider v-if="gridIndex + 1 < diploma.grid.length" />
                </div>
              </div>
              <div
                v-else
                style="background-color: #fff; color: #1d1d4e;
                padding: 24px 22px; border-radius: 8px;"
              >
                <p
                   v-if="diploma.dip_answers && diploma.dip_answers[0] && diploma.dip_answers[0].text"
                  v-html="allowBoldTag(toBold( diploma.dip_answers[0].text.replaceAll('[Imię dziecka]', `**${studentName.trim()}**`)))"
                />
              </div>
            </div>
          </div>
        </div>

        </screen-loader>
      </v-col>
    </v-row>
    <!-- <v-row
      align="center"
      justify="center"
    >
      <div
        :class="['download-container', {
          'download-container-sm-and-down': $vuetify.breakpoint.smAndDown,
        }]"
      >
        <v-btn
          color="#823189"
          :class="{
            'download-button-md-and-up': $vuetify.breakpoint.mdAndUp,
            'download-button-sm-and-down': $vuetify.breakpoint.smAndDown,
          }"
          style="color: #FFF;"
          @click="downloadProgressCard"
        >
          Pobierz
        </v-btn>
      </div>
    </v-row> -->
  </div>
</template>

<script>
import {
  generateServiceName, generateBannerImage, generateQuestionsAndAnswers, generateGrid, generateSectionIcon, allowBoldTag, toBold,
} from '@/utils/helpers';
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';

export default {
  name: 'EvaluationPlatform',
  components: {
    LeadBaner: () => import('@/components/LeadBaner.vue'),
    ScreenLoader: () => import('@/components/ScreenLoader.vue'),
  },
  props: {
    shortURL: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      diplomaData: {},
      studentName: '',
      studentFullName: '',
      serviceName: '',
      bannerImage: '',
      serviceIcon: '',
      serviceDBName: '',
      avatarUrl: '',
      loading: false,
    };
  },
  created() {
    this.fetchDiplomaData();
  },
  methods: {
    allowBoldTag,
    toBold,
    generateServiceName,
    generateBannerImage,
    generateQuestionsAndAnswers,
    generateGrid,
    generateSectionIcon,
    // async downloadProgressCard() {
    //   const w = document.getElementById('capture')?.offsetWidth;
    //   const h = document.getElementById('capture')?.offsetHeight;

    //   await html2canvas(document.getElementById('capture'), { scale: 3 }).then((canvas) => {
    //     const img = canvas.toDataURL('image/jpeg', 1);
    //     // const options = { pagesplit: true };
    //     const doc = new JsPDF('p', 'px', [w, h]);
    //     doc.addImage(img, 'JPEG', 0, 0, w, h);
    //     doc.save(`karta_postępu_uzytkownika_${this.studentName}_z_przedmiotu_${this.serviceDBName}.pdf`);
    //   });
    // },
    async downloadProgressCard() {
      this.loading = true;

      setTimeout(async () => {
        html2canvas(document.getElementById('capture'), { scale: 2, imageTimeout: 15000 }).then((canvas) => {
          const imgWidth = 208;
          const pageHeight = 295;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;
          heightLeft -= pageHeight;
          const doc = new JsPDF('p', 'mm');
          doc.addImage(canvas, 'PNG', 5, position, imgWidth - 5, imgHeight, '', 'FAST');
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(canvas, 'PNG', 5, position, imgWidth - 5, imgHeight, '', 'FAST');
            heightLeft -= pageHeight;
          }
          doc.save(`karta_postępu_uzytkownika_${this.studentName}_z_przedmiotu_${this.serviceDBName}.pdf`);
          this.loading = false;
        });
      }, 5000);
    },
    fetchDiplomaData() {
      this.$http.get(`/api/diploma/diploma-for-client/${this.shortURL}`).then((result) => {
        this.diplomaData = result.data.map((x) => ({ ...x, questionsAndAnswers: this.generateQuestionsAndAnswers(x.dip_questions, x.dip_answers), grid: this.generateGrid(x.evaluationAnswers, x.dip_questions, x.dip_answers) }));
        this.studentFullName = result?.data[0]?.student_full_name;
        this.studentName = result?.data[0]?.student_name;
        this.serviceDBName = result?.data[0]?.service_name;

        const { bannerImage, serviceIcon } = this.generateBannerImage();
        this.bannerImage = bannerImage;
        this.serviceIcon = serviceIcon;
        this.serviceName = this.generateServiceName(this.serviceDBName);
        // this.diplomaData = result.data[0].answers.map((x) => ({ ...x, questionsAndAnswers: this.generateQuestionsAndAnswers(x.dip_questions, x.dip_answers), grid: this.generateGrid(x.evaluationAnswers, x.dip_questions, x.dip_answers) }));
        // this.studentFullName = result.data[0].studentFullName;
        // this.studentName = result.data[0].name;
        // this.serviceDBName = result.data[0].serviceName;
        // console.log('this.diplomaData', this.diplomaData);
      });
    },
  },
};
</script>

<style scoped  lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import '@/styles/import';

* {
  font-family: 'Poppins' !important;
  -webkit-print-color-adjust: exact !important;
}

.text-block-size {
  max-width: 514px;
  margin: auto;
}

.cover-view {
  width: 100%;
  height: 100%;
  background-color: $white;
  position: fixed;
  left: 0;
  top: 0;
}

.text-block-size {
  max-width: 514px;
  margin: auto;
}

.image-container-32 {
  width: 32px;
  height: 32px;
}

.diploma-record-pdf {
  width: 893px;
}

.diploma-record,
.diploma-record-pdf {
  margin-bottom: 100px;
  @include sm-down() {
    margin-bottom: 150px;
  }
}

.image-container-32 {
  width: 32px;
  height: 32px;
}

.evaluation-wrapper {
  max-width: 1110px;
  margin: auto;
}

.diploma-record {
  padding: 24px;
  margin-bottom: 100px;
  @include sm-down() {
    margin-bottom: 150px;
  }
}

.download-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 72px;
  background-color: #fff;
}

// .download-container-sm-and-down {
//   bottom: 56px;
// }

.download-button-sm-and-down {
  width: 311px;
}

.download-button-md-and-up {
  width: 540px;
}

.grid-4,
.grid-5 {
  min-height: 50px;
  display: grid;
}

.grid-5 {
  grid-template-columns: 200px 100px 100px 100px 100px 100px;
}

.grid-4 {
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}

.evaluation-show-block {
  height: 100%;
  margin: auto;
  max-width: 800px;
  overflow: auto;
  color: #000;
  padding: 3px;
}

.logo {
  width: 200px;
  margin-bottom: 32px;
}

.service-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.primary-block,
.secondary-block {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.primary-block {
  color: #1d1d4e;
  line-height: 18px;
  font-size: 14px;
  padding: 5px 0;
}

.secondary-block {
  color:  #1d1d4e;
}

.baner {
  display: grid;
  background-color: #823189;
  height: 250px;
  width: 100%;
  grid-template-columns: 40% 60%;
  border-radius: 8px;
}

.baner .baner-image {
  height: 100%;
}

.service-icon {
  width: 100px;
  height: 100px;
}

.service-title {
  margin-top: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.header-text {
  width: 60%;
  margin-bottom: 40px;
  margin-left: 20px;
  text-align: left;
}

.question-logo {
  width: 80px;
  height: 13px;
}

@media (min-width: 320px) and (max-width: 575px) {

  .primary-block {
    font-size: 8px;
    word-break: break-word;
  }

  .baner {
    display: grid;
    background-color: #823189;
    height: 120px;
    width: 100%;
    grid-template-columns: 40% 60%;
    border-radius: 8px;
  }

  .service-icon {
    width: 50px;
    height: 50px;
  }

  .service-title {
    margin-top: 10px;
    font-size: 14px;
  }

  .header-text {
    width: 80%;
  }

  .question-logo {
    width: 70px;
  }

}
</style>